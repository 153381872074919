'use client';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { type ChangeEvent, type FC, type FocusEvent, type KeyboardEvent, useMemo, useRef } from 'react';
export type OtpProps = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
  disabled?: boolean;
};
export const RE_DIGIT = new RegExp(/^\d+$/);
export const Otp: FC<OtpProps> = props => {
  const {
    value,
    valueLength,
    onChange,
    disabled = false
  } = props;
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: Array<string> = [];
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [value, valueLength]);
  const focusToNextInput = (crtIdx: number) => {
    const nextIdx = crtIdx + 1;
    const nextInputRef = inputsRef.current[nextIdx];
    if (nextInputRef) {
      nextInputRef.focus();
    }
  };
  const focusToPrevInput = (crtIdx: number) => {
    const prevIdx = crtIdx - 1;
    const prevInputRef = inputsRef.current[prevIdx];
    if (prevInputRef) {
      prevInputRef.focus();
    }
  };
  const inputOnChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }
    const nextInputEl = inputsRef.current[idx + 1];

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }
    targetValue = isTargetValueDigit ? targetValue : ' ';
    const targetValueLength = targetValue.length;
    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);
      onChange(newValue);
      if (!isTargetValueDigit) {
        return;
      }
      focusToNextInput(idx);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      target.blur();
    }
  };
  const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const {
      key
    } = e;
    const target = e.target as HTMLInputElement;
    const idx = inputsRef.current.findIndex(ref => ref.id === target.id);
    if (!idx) {
      return;
    }
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(idx);
    }
    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(idx);
    }
    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);
    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }
    focusToPrevInput(idx);
  };
  const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    const {
      target
    } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null;
    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }
    target.setSelectionRange(0, target.value.length);
  };
  return <Box component="div" display="flex" width="100%" maxWidth="360px" columnGap="10px" data-sentry-element="Box" data-sentry-component="Otp" data-sentry-source-file="index.tsx">
      {valueItems.map((digit, idx) => <TextField key={idx} inputRef={(ref: any) => inputsRef.current[idx] = ref} inputProps={{
      inputMode: 'numeric',
      pattern: 'd{1}',
      maxLength: valueLength,
      autoComplete: 'one-time-code',
      sx: {
        textAlign: 'center'
      },
      onKeyDown: inputOnKeyDown,
      onFocus: inputOnFocus,
      onChange: (e: ChangeEvent<HTMLInputElement>) => inputOnChange(e, idx)
    }} value={digit} disabled={disabled} />)}
    </Box>;
};