'use client';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { type FC, useState } from 'react';
type Props = {
  isVisible?: boolean;
  title: string;
  message?: string;
  marginX?: string;
  position?: 'absolute' | 'sticky' | 'relative' | 'fixed';
};
export const NewFeatureBanner: FC<Props> = (props: Props) => {
  const {
    isVisible = false,
    title,
    message = 'Une nouvelle fonctionnalité est arrivée',
    marginX = '-1rem',
    position = 'sticky'
  } = props;
  const [open, setOpen] = useState(true);
  if (!isVisible) return null;
  return <Box component="div" position={position} top={0} zIndex={1} marginX={marginX} paddingRight={2} data-sentry-element="Box" data-sentry-component="NewFeatureBanner" data-sentry-source-file="index.tsx">
      <Collapse in={open} data-sentry-element="Collapse" data-sentry-source-file="index.tsx">
        <Alert
      // severity="info"
      variant="filled" icon={false} action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => {
        setOpen(false);
      }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>} sx={{
        backgroundColor: 'primary.main',
        '& .MuiAlert-message': {
          flex: 1
        }
      }} data-sentry-element="Alert" data-sentry-source-file="index.tsx">
          <Stack direction="row" gap={1} justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
            <Typography fontWeight={700} color="white" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {title}
            </Typography>
            <Typography fontWeight={700} color="white" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              |
            </Typography>
            <Typography color="white" data-sentry-element="Typography" data-sentry-source-file="index.tsx">{message}</Typography>
          </Stack>
        </Alert>
      </Collapse>
    </Box>;
};