'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#e8faea',
      gradient: {
        main: 'linear-gradient(to right, #5BC92A 0%, #15CB31 51.56%, #13BF56 100%)',
        dark: 'linear-gradient(to right, #50b125 0%, #12b12a 51.56%, #11a74b 100%)',
        light:
          'linear-gradient(to right, #50b125 0%, #12b12a 51.56%, #11a74b 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-s'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
