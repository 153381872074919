'use client';

import type { StyledComponent } from '@emotion/styled';
import StepConnector, { stepConnectorClasses, type StepConnectorProps } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
export const PoConnector: StyledComponent<StepConnectorProps> = styled(StepConnector)(({
  theme
}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.light
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderTopWidth: 3,
    borderRadius: 1
  }
}));