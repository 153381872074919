'use client';

import createCache, { type Options } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as BaseThemeProvider, type Theme } from '@mui/material/styles';
import { useServerInsertedHTML } from 'next/navigation';
import { type FC, type PropsWithChildren, useState } from 'react';
export type Props = {
  theme: Theme;
  options: Options;
};

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export const ThemeProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    theme,
    options,
    children
  } = props;
  const [{
    cache,
    flush
  }] = useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: string[] = [];
    cache.insert = (...args) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return {
      cache,
      flush
    };
  });
  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    let styles = '';
    for (const name of names) {
      styles += cache.inserted[name];
    }
    return <style key={cache.key} data-emotion={`${cache.key} ${names.join(' ')}`} dangerouslySetInnerHTML={{
      __html: styles
    }} />;
  });
  return <CacheProvider value={cache} data-sentry-element="CacheProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="theme.provider.tsx">
      <BaseThemeProvider theme={theme} data-sentry-element="BaseThemeProvider" data-sentry-source-file="theme.provider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="theme.provider.tsx" />
        {children}
      </BaseThemeProvider>
    </CacheProvider>;
};