'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#e9f4fb',
      gradient: {
        main: 'linear-gradient(to right, #19A2A7 0%, #2490D9 51.56%, #517CBD 100%)',
        dark: 'linear-gradient(to right, #14868a 0%, #1d79b7 51.56%, #41659a 100%)',
        light:
          'linear-gradient(to right, #14868a 0%, #1d79b7 51.56%, #41659a 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-c'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
