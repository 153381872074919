'use client';

import { MuiTelInput, type MuiTelInputProps, matchIsValidTel as matchIsValidTelBase } from 'mui-tel-input';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export const matchIsValidTel = matchIsValidTelBase;
export type PhoneNumberInputProps<T extends FieldValues = FieldValues> = Omit<MuiTelInputProps, 'name'> & {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  /**
   * You override the MUI's PhoneNumber component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of PhoneNumber.
   */
  component?: typeof MuiTelInput;
};
export function PhoneNumberInput<TFieldValues extends FieldValues = FieldValues>(props: PhoneNumberInputProps<TFieldValues>) {
  const {
    control,
    name,
    component: PhoneNumberComponent = MuiTelInput,
    helperText = undefined,
    ...rest
  } = props;
  return <Controller control={control} name={name} rules={{
    validate: matchIsValidTel
  }} render={({
    field: {
      ref,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => <PhoneNumberComponent {...rest as any} {...field} inputRef={ref} defaultCountry="FR" preferredCountries={['FR', 'CH', 'IT', 'BE', 'US']} forceCallingCode langOfCountryName="fr" helperText={invalid && error?.message ? error?.message : helperText} error={invalid}
  // onChange={handleChange}
  // {...baseProps}
  />} data-sentry-element="Controller" data-sentry-component="PhoneNumberInput" data-sentry-source-file="index.tsx" />;
}