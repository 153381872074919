'use client';

import { type Theme, alpha, createTheme } from '@mui/material/styles';

export const applyPrimaryColor = (theme: Theme): Theme => {
  return createTheme(theme, {
    typography: {
      h1: {
        color: theme.palette.primary.main,
      },
    },

    palette: {
      // divider: theme.palette.primary.main,

      action: {
        hover: alpha(theme.palette.primary.main, 0.2),
        // active: alpha(theme.palette.primary.main, 0.2),
        selected: alpha(theme.palette.primary.main, 0.2),
        // focuse: alpha(theme.palette.primary.main, 0.2),
      },
    },
  });
};
