'use client';
import { alpha, createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';

const defaultTheme = createTheme();

// Create a theme instance.
const base = createTheme({
  typography: {
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeightBold: 900,
    fontWeightMedium: 700,
    fontWeightRegular: 400,
    fontWeightLight: 200,

    allVariants: {
      textTransform: 'none',
      lineHeight: 1.75,
    },

    h1: {
      color: '#009ca0',
      fontWeight: 900,
      fontSize: 24,
    },
    h2: {
      color: '#2D5B5B',
      fontWeight: 900,
      fontSize: 16,
    },
    h3: {
      color: '#2D5B5B',
      fontWeight: 900,
      fontSize: 14,
    },
    h4: {
      color: '#2D5B5B',
      fontWeight: 900,
      fontSize: 14,
    },
    h5: {
      color: '#2D5B5B',
      fontWeight: 900,
      fontSize: 14,
    },
    h6: {
      color: '#2D5B5B',
      fontWeight: 900,
      fontSize: 14,
    },
    body1: {
      color: '#2D5B5B',
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      color: '#2D5B5B',
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle1: {
      color: '#2D5B5B',
      fontSize: 14,
      fontWeight: 700,
    },
    subtitle2: {
      color: '#2D5B5B',
      fontSize: 14,
      fontWeight: 700,
    },
    overline: {
      color: '#2D5B5B',
      fontSize: 14,
      fontWeight: 700,
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'none',
    },
  },

  //        0  1  2   3   4   5   6
  spacing: [0, 8, 16, 24, 32, 48, 96],

  breakpoints: {
    values: {
      none: 0,
      xs: 480,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1536,
    },
  },

  palette: {
    mode: 'light',

    // contrastThreshold: 2,

    background: {
      default: '#f4f9f9',
      primary: '#BFF3F3',
      gray: '#2D5B5B',
      paper: '#fff',
      gradient: {
        main: 'linear-gradient(to right, #00ced1 0%, #009ca0 100%)',
        dark: 'linear-gradient(to right, #73e4e5 0%, #00ced1 100%)',
        light: 'linear-gradient(to right, #73e4e5 0%, #00ced1 100%)',
        contrastText: '#fff',
      },
    },

    primary: defaultTheme.palette.augmentColor({
      color: {
        main: '#009ca0',
        contrastText: '#FFFFFF',
      },
      name: 'primary',
    }),
    secondary: defaultTheme.palette.augmentColor({
      color: {
        main: '#00ced1',
      },
      name: 'secondary',
    }),
    gradient: {
      main: '#000',
      dark: '#000',
      light: '#000',
      contrastText: '#fff',
    },

    divider: '#7b9899',

    accepted: defaultTheme.palette.augmentColor({
      color: {
        main: '#52c41a',
        light: '#F6FFED',
        dark: '#52c41a',
        contrastText: '#FFF',
      },
      name: 'accepted',
    }),
    pending: defaultTheme.palette.augmentColor({
      color: {
        main: '#faad14',
        light: '#FFFBE6',
        dark: '#faad14',
        contrastText: '#FFF',
      },
      name: 'pending',
    }),
    refused: defaultTheme.palette.augmentColor({
      color: {
        main: '#ff4d4f',
        light: '#FFF2F0',
        dark: '#ff4d4f',
        contrastText: '#FFF',
      },
      name: 'refused',
    }),
    canceled: defaultTheme.palette.augmentColor({
      color: {
        main: '#F59AA4',
        light: '#FFF0F6',
        dark: '#c41d7f',
        contrastText: '#FFF',
      },
      name: 'canceled',
    }),
    expired: defaultTheme.palette.augmentColor({
      color: {
        main: '#c41d7f',
        light: alpha('#c41d7f', 0.2),
        contrastText: '#FFF',
      },
      name: 'expired',
    }),
    deleted: defaultTheme.palette.augmentColor({
      color: {
        main: '#ff4a4a',
        contrastText: '#FFF',
      },
      name: 'deleted',
    }),

    gray: defaultTheme.palette.augmentColor({
      color: {
        main: '#2D5B5B',
        light: alpha('#2D5B5B', 0.2),
      },
      name: 'gray',
    }),

    common: {
      black: '#000',
      white: '#fff',
    },

    poleEmploi: defaultTheme.palette.augmentColor({
      color: {
        main: '#1b2e57',
      },
      name: 'poleEmploi',
    }),
    linkedIn: defaultTheme.palette.augmentColor({
      color: {
        main: '#0077b5',
      },
      name: 'linkedIn',
    }),

    'disc-d': defaultTheme.palette.augmentColor({
      color: {
        main: '#FF3333',
        light: '#D82D2D',
        contrastText: '#FFF',
      },
      name: 'disc-d',
    }),
    'disc-d-i': defaultTheme.palette.augmentColor({
      color: {
        main: '#FE7627',
        light: '#DC6724',
        contrastText: '#FFF',
      },
      name: 'disc-d-i',
    }),
    'disc-i': defaultTheme.palette.augmentColor({
      color: {
        main: '#FCB91B',
        light: '#E0A61E',
        contrastText: '#FFF',
      },
      name: 'disc-i',
    }),
    'disc-i-s': defaultTheme.palette.augmentColor({
      color: {
        main: '#94C724',
        light: '#79A41D',
        contrastText: '#FFF',
      },
      name: 'disc-i-s',
    }),
    'disc-s': defaultTheme.palette.augmentColor({
      color: {
        main: '#15CB31',
        light: '#109C25',
        contrastText: '#FFF',
      },
      name: 'disc-s',
    }),
    'disc-s-c': defaultTheme.palette.augmentColor({
      color: {
        main: '#10B17E',
        light: '#0C8E65',
        contrastText: '#FFF',
      },
      name: 'disc-s-c',
    }),
    'disc-c': defaultTheme.palette.augmentColor({
      color: {
        main: '#2490D9',
        light: '#1D77B4',
        contrastText: '#FFF',
      },
      name: 'disc-c',
    }),
    'disc-c-d': defaultTheme.palette.augmentColor({
      color: {
        main: '#88639A',
        light: '#6E4F7D',
        contrastText: '#FFF',
      },
      name: 'disc-c-d',
    }),

    admin: defaultTheme.palette.augmentColor({
      color: {
        main: '#52c41a',
        light: '#F6FFED',
        dark: '#52c41a',
        contrastText: '#FFF',
      },
      name: 'linkedIn',
    }),
    tooler: defaultTheme.palette.augmentColor({
      color: {
        main: '#8BB4AD',
        light: '#E6FFFB',
        dark: '#8BB4AD',
        contrastText: '#FFF',
      },
      name: 'linkedIn',
    }),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            borderRadius: 1_000_000,
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'gradient' && {
                background: theme.palette.background.gradient.main,
                '&:hover': {
                  background: theme.palette.background.gradient.dark,
                },
              }),
            ...(ownerState.variant === 'contained' &&
              ownerState.disabled === true &&
              ownerState.color === 'gradient' && {
                filter: 'grayscale(1)',
                opacity: 0.6,
                color: 'white !important',
              }),
          };
        },
        text: {
          textDecoration: 'underline',
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

export default applyPrimaryColor(base);
