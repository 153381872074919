'use client';

import { Avatar as MuiAvatar } from '@mui/material';
import { env } from '@prismo-io/env';
import NextImage, { type ImageLoaderProps } from 'next/image';
import { type ComponentProps, type FC, type ReactNode, useMemo, useState } from 'react';
const isValidUrl = (toCheck: string): boolean => {
  try {
    const url = new URL(toCheck);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};
export const ImageLoader = (bucket = 'avatars') => {
  return (props: ImageLoaderProps) => {
    const {
      src,
      width,
      quality = 75
    } = props;
    if (isValidUrl(src)) {
      return src;
    }
    const url = new URL(`/api/storage/v1/object/public/${bucket}/${src}`, env.NEXT_PUBLIC_BASE_API_URL);
    url.searchParams.set('width', width.toString());
    url.searchParams.set('quality', quality.toString());
    return url.toString();
  };
};
type AvatarProps = Omit<ComponentProps<typeof NextImage>, 'loader' | 'src'> & {
  avatar?: Omit<ComponentProps<typeof MuiAvatar>, 'src'>;
  bucket?: string;
  src?: string | null;
  alt: string;
  replaceOnError?: ReactNode;
};
export const Avatar: FC<AvatarProps> = props => {
  const {
    bucket,
    replaceOnError = undefined,
    src = undefined,
    alt,
    avatar = {},
    ...rest
  } = props;
  const [isError, setIsError] = useState<boolean>(false);
  const withImage = useMemo(() => !isError && src !== undefined && src !== null, [src, isError]);
  return <MuiAvatar {...avatar} sx={{
    bgcolor: !withImage ? undefined : 'white',
    width: props.width ? props.width : 40,
    height: props.height ? props.height : 40,
    ...(avatar?.sx ?? {})
  }} data-sentry-element="MuiAvatar" data-sentry-component="Avatar" data-sentry-source-file="index.tsx">
      {withImage && <NextImage {...rest} alt={alt} src={src as string} loader={ImageLoader(bucket)} onError={() => setIsError(true)} />}
      {!withImage && replaceOnError}
    </MuiAvatar>;
};