'use client';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect, { type SelectProps as MuiSelectProps } from '@mui/material/Select';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export type SelectInputProps<T extends FieldValues = FieldValues> = Omit<MuiSelectProps, 'name' | 'size'> & {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  /**
   * You override the MUI's Select component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of Select.
   */
  component?: typeof MuiSelect;
  helperText?: string;
  size?: 'small' | 'medium';
};
export function SelectInput<TFieldValues extends FieldValues = FieldValues>(props: SelectInputProps<TFieldValues>) {
  const {
    control,
    name,
    label,
    size = 'small',
    helperText,
    fullWidth,
    required = false,
    component: SelectComponent = MuiSelect,
    ...baseProps
  } = props;
  const labelId = `label-id-of-${name}`;
  return <Controller control={control} name={name} render={({
    field: {
      ref,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => <FormControl fullWidth={fullWidth}>
          <InputLabel id={labelId} size={size === 'small' ? 'small' : 'normal'} required={required}>
            {label}
          </InputLabel>
          <SelectComponent inputRef={ref} labelId={labelId} label={label} size={size} error={invalid} fullWidth={fullWidth} required={required} {...baseProps} {...field} />
          {(helperText || !!error) && <FormHelperText>
              {error ? error.message : helperText}
            </FormHelperText>}
        </FormControl>} data-sentry-element="Controller" data-sentry-component="SelectInput" data-sentry-source-file="index.tsx" />;
}