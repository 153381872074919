'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#f4f9e9',
      gradient: {
        main: 'linear-gradient(to right, #D1BF1F 0%, #94C724 51.56%, #5BC92A 100%)',
        dark: 'linear-gradient(to right, #bdad1c 0%, #85b320 51.56%, #52b525 100%)',
        light:
          'linear-gradient(to right, #bdad1c 0%, #85b320 51.56%, #52b525 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-i-s'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
