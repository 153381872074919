'use client';

import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import MuiRadioGroup, { type RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';
import Stack from '@mui/system/Stack';
import type { ReactNode } from 'react';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export type FavoriteRadioGroupProps<T extends FieldValues = FieldValues> = Omit<MuiRadioGroupProps, 'name'> & {
  label?: ReactNode;
  helperText?: string;
  disabled?: boolean;
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control: Control<T>;
  /**
   * You override the MUI's MuiCheckbox component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of MuiCheckbox.
   */
  component?: typeof MuiRadioGroup;
};
export function FavoriteRadioGroup<TFieldValues extends FieldValues = FieldValues>(props: FavoriteRadioGroupProps<TFieldValues>) {
  const {
    label = undefined,
    helperText = undefined,
    disabled = false,
    control,
    name,
    component: RadioGroupComponent = MuiRadioGroup,
    ...baseProps
  } = props;
  return <>
      <Controller control={control} name={name} render={({
      field: {
        ref,
        value,
        ...field
      },
      fieldState: {
        invalid,
        error
      }
    }) => <Stack flexDirection="row" alignItems="center">
            {label && <Typography>{label} :</Typography>}
            <FormControl hiddenLabel={!label} error={invalid}>
              <RadioGroupComponent row {...baseProps} {...field}>
                <FormControlLabel value={'1'} label={'1'} labelPlacement="top" disabled={disabled} sx={{
            '& .MuiFormControlLabel-label': {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              fontWeight: 'bolder'
            },
            '& .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label': {
              color: 'white'
            }
          }} control={<Radio checked={value === '1'} icon={<StarBorderOutlinedIcon />} checkedIcon={<StarOutlinedIcon />} sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 48
            }
          }} />} />
                <FormControlLabel value={'2'} label={'2'} labelPlacement="top" disabled={disabled} sx={{
            '& .MuiFormControlLabel-label': {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              fontWeight: 'bolder'
            },
            '& .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label': {
              color: 'white'
            }
          }} control={<Radio checked={value === '2'} icon={<StarBorderOutlinedIcon />} checkedIcon={<StarOutlinedIcon />} sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 48
            }
          }} />} />
                <FormControlLabel value={'3'} label={'3'} labelPlacement="top" disabled={disabled} sx={{
            '& .MuiFormControlLabel-label': {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              fontWeight: 'bolder'
            },
            '& .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label': {
              color: 'white'
            }
          }} control={<Radio checked={value === '3'} icon={<StarBorderOutlinedIcon />} checkedIcon={<StarOutlinedIcon />} sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 48
            }
          }} />} />
                {(invalid && error?.message || helperText) && <FormHelperText sx={{
            marginLeft: 0
          }}>
                    {invalid && error?.message ? error?.message : helperText}
                  </FormHelperText>}
              </RadioGroupComponent>
            </FormControl>
          </Stack>} data-sentry-element="Controller" data-sentry-source-file="index.tsx" />
    </>;
}