'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#f4fbea',
    },

    primary: theme.palette.augmentColor({
      color: {
        main: '#7ABB17',
        contrastText: '#FFF',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#94D82D',
        contrastText: '#FFF',
      },
      name: 'secondary',
    }),
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
