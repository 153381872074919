'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#fff1e9',
      gradient: {
        main: 'linear-gradient(to right, #FF532E 0%, #FE7627 51.56%, #FD9622 100%)',
        dark: 'linear-gradient(to right, #c84023 0%, #cf6020 51.56%, #cb781c 100%)',
        light:
          'linear-gradient(to right, #c84023 0%, #cf6020 51.56%, #cb781c 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-d-i'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
