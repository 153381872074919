'use client';

import { type FC, type MouseEvent, useCallback } from 'react';
import { Button, type ButtonProps } from '../../components/button';
import { useAppShell } from './context';
type TriggerActions = 'open' | 'close' | 'toggle';
type AppShellTriggerProps = ButtonProps & {
  actionType?: TriggerActions;
};
export const AppShellTrigger: FC<AppShellTriggerProps> = props => {
  const {
    actionType = 'toggle',
    onClick,
    ...buttonProps
  } = props;
  const {
    onOpen,
    onClose,
    onToggle
  } = useAppShell();
  const onTrigger = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    switch (actionType) {
      case 'close':
        onClose();
        break;
      case 'open':
        onOpen();
        break;
      case 'toggle':
        onToggle();
        break;
    }
    if (onClick) {
      onClick(e);
    }
  }, [actionType, onClick]);
  return <Button {...buttonProps} onClick={onTrigger} data-sentry-element="Button" data-sentry-component="AppShellTrigger" data-sentry-source-file="app-shell-trigger.tsx" />;
};