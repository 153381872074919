'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#f3eff5',
      gradient: {
        main: 'linear-gradient(to right, #517CBD 0%, #88639A 51.56%, #BF4D6A 100%)',
        dark: 'linear-gradient(to right, #4469a0 0%, #6f507d 51.56%, #a5435c 100%)',
        light:
          'linear-gradient(to right, #4469a0 0%, #6f507d 51.56%, #a5435c 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-c-d'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
