'use client';

import MuiCheckbox, { type CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import type { ReactNode } from 'react';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export type CheckboxInputProps<T extends FieldValues = FieldValues> = Omit<MuiCheckboxProps, 'name'> & {
  label?: ReactNode;
  helperText?: string;
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control: Control<T>;
  /**
   * You override the MUI's MuiCheckbox component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of MuiCheckbox.
   */
  component?: typeof MuiCheckbox;
};
export function CheckboxInput<TFieldValues extends FieldValues = FieldValues>(props: CheckboxInputProps<TFieldValues>) {
  const {
    label = undefined,
    helperText = undefined,
    control,
    name,
    component: CheckboxComponent = MuiCheckbox,
    ...baseProps
  } = props;
  return <Controller control={control} name={name} render={({
    field: {
      ref,
      value,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => <FormControl hiddenLabel={!label} error={invalid}>
          <FormControlLabel label={label} control={<CheckboxComponent {...baseProps} inputRef={ref} checked={value} {...field} />} />
          {(invalid && error?.message || helperText) && <FormHelperText sx={{
      marginLeft: 0
    }}>
              {invalid && error?.message ? error?.message : helperText}
            </FormHelperText>}
        </FormControl>} data-sentry-element="Controller" data-sentry-component="CheckboxInput" data-sentry-source-file="index.tsx" />;
}