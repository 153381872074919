'use client';

import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MuiRating, { type RatingProps as MuiRatingProps } from '@mui/material/Rating';
import { type ReactNode, useState } from 'react';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export type RatingInputProps<T extends FieldValues = FieldValues> = Omit<MuiRatingProps, 'name'> & {
  label?: ReactNode;
  helperText?: string;
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  max?: number;
  //   parseError?: (error: FieldError) => string
  control: Control<T>;
  component?: typeof MuiRating;
};
export function RatingInput<TFieldValues extends FieldValues = FieldValues>(props: RatingInputProps<TFieldValues>) {
  const {
    label = undefined,
    helperText = undefined,
    control,
    name,
    max = 3,
    component: RatingComponent = MuiRating,
    ...baseProps
  } = props;
  return <Controller control={control} name={name} render={({
    field: {
      ref,
      value,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => <FormControl hiddenLabel={!label} error={invalid}>
          <FormControlLabel label={label} labelPlacement="start" sx={{
      justifyContent: 'flex-end',
      gap: 1,
      marginLeft: 0
    }} control={<RatingComponent {...baseProps} value={value} {...field} precision={1} max={max} icon={<CircleIcon fontSize="inherit" />} emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />} sx={{
      gap: 1,
      color: 'primary.main'
    }} />} />
          {(invalid && error?.message || helperText) && <FormHelperText sx={{
      marginLeft: 0
    }}>
              {invalid && error?.message ? error?.message : helperText}
            </FormHelperText>}
        </FormControl>} data-sentry-element="Controller" data-sentry-component="RatingInput" data-sentry-source-file="index.tsx" />;
}