'use client';

import { Add, Delete } from '@mui/icons-material';
import { Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import Image from 'next/image';
import { type ChangeEvent, type ComponentProps, useEffect, useRef, useState } from 'react';
import { CircleStencil, Cropper, type CropperRef, RectangleStencil } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { type Control, type FieldValues, type Path, useController } from 'react-hook-form';
export type ImageInputProps<T extends FieldValues = FieldValues> = {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  stencilComponent?: ComponentProps<typeof Cropper>['stencilComponent'];
  stencilProps?: ComponentProps<typeof Cropper>['stencilProps'];
  boxProps?: Omit<ComponentProps<typeof Box>, 'onMouseOver' | 'onMouseOut' | 'position' | 'overflow' | 'component'>;
};
export interface Image {
  type?: string;
  blob?: Blob;
  src: string;
}
export { CircleStencil, RectangleStencil };
export function ImageInput<TFieldValues extends FieldValues = FieldValues>(props: ImageInputProps<TFieldValues>) {
  const {
    control,
    name,
    stencilProps,
    stencilComponent,
    boxProps
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<CropperRef>(null);
  const [editImage, setEditImage] = useState<boolean>(false);
  const [image, setImage] = useState<Image | null>(null);
  const [isHover, setIsHover] = useState<boolean>(false);
  const {
    field
  } = useController({
    control,
    name
  });
  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
    // Reference to the DOM input element
    const {
      files
    } = event.target;

    // Ensure that you have a file before attempting to read it
    if (files?.[0]) {
      const file = files[0];

      // Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // Get the image type from the extension. It's the simplest way, though be careful it can lead to an incorrect result:
      setImage({
        src: blob,
        type: file.type
      });
      setEditImage(true);
    }
    // Clear the event target value to give the possibility to upload the same image:
    event.target.value = '';
  };
  useEffect(() => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    return () => {
      if (image?.src) {
        URL.revokeObjectURL(image.src);
      }
    };
  }, [image]);
  const onCrop = () => {
    const canvas = cropperRef.current?.getCanvas();
    if (canvas) {
      canvas.toBlob(blob => {
        if (blob) {
          const src = URL.createObjectURL(blob);

          // Get the image type from the extension. It's the simplest way, though be careful it can lead to an incorrect result:
          setImage({
            src,
            type: 'image/png',
            blob
          });
          field.onChange({
            src,
            type: 'image/png',
            blob
          });
        }
        setEditImage(false);
      }, 'image/png');
    }
  };
  const onDelete = () => {
    setImage(null);
    field.onChange(null);
  };
  return <Box component="div" data-sentry-element="Box" data-sentry-component="ImageInput" data-sentry-source-file="index.tsx">
      <input hidden ref={inputRef} type="file" accept="image/*" onChange={onLoadImage} />

      <Box component="div" position="relative" overflow="hidden" minWidth={20} minHeight={20} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} {...boxProps} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {image?.src && <>
            <Image fill src={image.src} alt="" style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center'
        }} />
            {isHover && <Box component="div" position="absolute" top={0} left={0} right={0} bottom={0} bgcolor="#2D5B5B88" alignItems="center" justifyItems="center" display="flex">
                <IconButton onClick={onDelete} sx={{
            margin: 'auto'
          }}>
                  <Delete />
                </IconButton>
              </Box>}
          </>}
        {(!image || !image.src) && <Box component="div" position="absolute" top={0} left={0} right={0} bottom={0} alignItems="center" justifyItems="center" display="flex" bgcolor="#2D5B5B88">
            <ButtonBase onClick={onUpload} sx={{
          margin: 'auto'
        }}>
              <Add color="gray" />
            </ButtonBase>
          </Box>}
      </Box>

      <Dialog open={editImage} onClose={() => setEditImage(false)} data-sentry-element="Dialog" data-sentry-source-file="index.tsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
          <Cropper ref={cropperRef} src={image?.src ? image.src : ''} stencilProps={stencilProps} stencilComponent={stencilComponent} data-sentry-element="Cropper" data-sentry-source-file="index.tsx" />
        </DialogContent>
        <DialogActions data-sentry-element="DialogActions" data-sentry-source-file="index.tsx">
          <Button variant="contained" onClick={onCrop} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </Box>;
}