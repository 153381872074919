'use client';

import { FormControl, FormControlLabel, FormLabel } from '@mui/material';
import MuiSwitch, { type SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export type SwitchInputProps<T extends FieldValues = FieldValues> = Omit<MuiSwitchProps, 'name'> & {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  /**
   * You override the MUI's TextField component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of TextField.
   */
  component?: typeof MuiSwitch;
};
export function SwitchInput<TFieldValues extends FieldValues = FieldValues>(props: SwitchInputProps<TFieldValues>) {
  const {
    control,
    name,
    component: SwitchComponent = MuiSwitch,
    ...baseProps
  } = props;
  return <Controller control={control} name={name} render={({
    field: {
      value,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => <SwitchComponent {...field} checked={value} {...baseProps} />} data-sentry-element="Controller" data-sentry-component="SwitchInput" data-sentry-source-file="index.tsx" />;
}