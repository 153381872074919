'use client';

import { CheckOutlined, CloseOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Paper, Popover, Stack, Typography } from '@mui/material';
import { type FC, type FocusEvent, type MouseEvent, useCallback, useMemo, useState } from 'react';
import { type FieldValues, useController } from 'react-hook-form';
import { AccuracyProgress } from '../accuracy-progress';
import { TextFieldInput, type TextFieldInputProps } from '../text-field-input';
type PasswordRequirementProps = {
  label: string;
  valid: boolean;
};
const PasswordRequirement: FC<PasswordRequirementProps> = props => {
  const {
    valid,
    label
  } = props;
  return <Typography fontSize="small" alignItems="center" gap={1} display="flex" color={valid ? 'success.main' : 'error.main'} data-sentry-element="Typography" data-sentry-component="PasswordRequirement" data-sentry-source-file="index.tsx">
      {valid ? <CheckOutlined fontSize="small" /> : <CloseOutlined fontSize="small" />}{' '}
      {label}
    </Typography>;
};
export type PasswordRequirementT = {
  re: RegExp;
  label: string;
};
const getStrength = (password: string, requirements: PasswordRequirementT[] = []) => {
  if (!password || password?.trim() === '') {
    return 0;
  }
  if (requirements.length === 0) {
    return 100;
  }
  let multiplier = password.length >= 8 ? 0 : 1;
  for (const requirement of requirements) {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  }
  return Math.max(100 - 100 / (requirements.length + 1) * multiplier, 10);
};
export type PasswordInputProps<T extends FieldValues = FieldValues> = Omit<TextFieldInputProps<T>, 'component' | 'type'> & {
  requirements?: PasswordRequirementT[];
};
export function PasswordInput<TFieldValues extends FieldValues = FieldValues>(props: PasswordInputProps<TFieldValues>) {
  const {
    InputProps = {},
    control,
    name,
    helperText = undefined,
    requirements = [],
    ...textFieldProps
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [strengthEl, setStrengthEl] = useState<HTMLDivElement | null>(null);
  const strengthId = useMemo(() => !!strengthEl ? 'strength-popover' : undefined, [strengthEl]);
  const strengthOpened = useMemo(() => Boolean(strengthEl), [strengthEl]);
  const onFocusCapture = useCallback((event: FocusEvent<HTMLDivElement>) => {
    if (requirements.length === 0) {
      return;
    }
    setStrengthEl(event.currentTarget);
  }, [requirements]);
  const onBlurCapture = useCallback(() => {
    setStrengthEl(null);
  }, []);
  const {
    field: {
      value: password
    }
  } = useController({
    control,
    name
  });
  const strength = useMemo(() => getStrength(password, requirements), [password, requirements]);
  return <>
      <TextFieldInput {...textFieldProps} helperText={requirements.length === 0 ? helperText : undefined} disableError={requirements.length !== 0} control={control} name={name} type={showPassword ? 'text' : 'password'} aria-describedby={strengthId} onFocusCapture={onFocusCapture} onBlurCapture={onBlurCapture} InputProps={{
      ...InputProps,
      endAdornment: <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
    }} data-sentry-element="TextFieldInput" data-sentry-source-file="index.tsx" />
      <Popover id={strengthId} open={strengthOpened} anchorEl={strengthEl} disableAutoFocus onClose={onBlurCapture} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }} data-sentry-element="Popover" data-sentry-source-file="index.tsx">
        <Stack padding={1} gap={1} width={strengthEl?.clientWidth} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <AccuracyProgress value={strength} variant="determinate" sx={{
          height: 4
        }} data-sentry-element="AccuracyProgress" data-sentry-source-file="index.tsx" />
          {requirements.map(requirement => {
          const valid = requirement.re.test(password);
          return <PasswordRequirement key={requirement.re.toString()} label={requirement.label} valid={valid} />;
        })}
        </Stack>
      </Popover>
    </>;
}