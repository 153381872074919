'use client';

import './module-augmentation';
export * as AppPalettes from './app.theme';
export * as ToolsPalettes from './tools.theme';
export * as AIPalettes from './ai.theme';
export * as DiscDPalettes from './disc-d.theme';
export * as DiscDIPalettes from './disc-d-i.theme';
export * as DiscIPalettes from './disc-i.theme';
export * as DiscISPalettes from './disc-i-s.theme';
export * as DiscSPalettes from './disc-s.theme';
export * as DiscSCPalettes from './disc-s-c.theme';
export * as DiscCPalettes from './disc-c.theme';
export * as DiscCDPalettes from './disc-c-d.theme';

export { type Theme } from '@mui/material/styles';
