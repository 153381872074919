'use client';

import type { PaletteMode } from '@mui/material';
import { createContext } from 'react';

type Ctx = {
  prefersDarkMode: boolean;
  mode: PaletteMode;
  toggleColorMode: () => void;
};

export const ColorModeContext = createContext<Ctx>({
  prefersDarkMode: false,
  mode: 'light',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {},
});
