'use client';

import type { ReactNode } from 'react';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
import { DatePicker, type DatePickerProps } from '../date-picker';
export type DatePickerInputProps<TDate extends Date, T extends FieldValues = FieldValues> = Omit<DatePickerProps<TDate>, 'name'> & {
  label?: ReactNode;
  helperText?: string;
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control: Control<T>;
  /**
   * You override the MUI's MuiCheckbox component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of MuiCheckbox.
   */
  component?: typeof DatePicker;
};
export function DatePickerInput<TDate extends Date, TFieldValues extends FieldValues = FieldValues>(props: DatePickerInputProps<TDate, TFieldValues>) {
  const {
    // label = undefined,
    helperText = undefined,
    control,
    name,
    component: DatePickerComponent = DatePicker,
    ...baseProps
  } = props;
  return <Controller control={control} name={name} render={({
    field: {
      ref,
      value,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => {
    // const dateValue =
    //   typeof value === 'string' ? new Date(value) ?? null : null;
    const dateValue = typeof value === 'string' ? new Date(value) ?? null : typeof value === 'object' ? value : null;
    return <DatePickerComponent {...baseProps} inputRef={ref} value={dateValue as any} {...field} slotProps={{
      actionBar: {
        actions: ['accept', 'clear']
      },
      textField: {
        helperText: invalid && error?.message ? error?.message : helperText
      }
    }} />;
    // <FormControl hiddenLabel={!label} error={invalid}>
    //   <FormControlLabel
    //     label={label}
    //     control={
    //       <DatePickerComponent {...baseProps} inputRef={ref} value={value} {...field} />
    //     }
    //   />
    //   {((invalid && error?.message) || helperText) && (
    //     <FormHelperText sx={{ marginLeft: 0 }}>
    //       {invalid && error?.message ? error?.message : helperText}
    //     </FormHelperText>
    //   )}
    // </FormControl>
  }} data-sentry-element="Controller" data-sentry-component="DatePickerInput" data-sentry-source-file="index.tsx" />;
}