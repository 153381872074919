'use client';

import type { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/system';
import { type FC, type ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { SideToShowOption } from './types';
type AppShellContextT = {
  onOpen: (side?: Side) => void;
  onClose: (side?: Side) => void;
  onToggle: (side?: Side) => void;
  sideToShow: SideToShowOption;
  isLargerThanLg: boolean;
};
type Side = 'LEFT' | 'RIGHT';
const AppShellContext = createContext<AppShellContextT | undefined>(undefined);
export const useAppShell = () => {
  const ctx = useContext(AppShellContext);
  if (!ctx) {
    throw new Error('useAppShell() must be call inside <AppShellProvider/>');
  }
  return ctx;
};
export type AppShellProviderProps = {
  sideToShow?: SideToShowOption;
  children: ReactNode;
};
export const AppShellProvider: FC<AppShellProviderProps> = props => {
  const {
    sideToShow: defaultSide = SideToShowOption.OnlyLeft,
    children
  } = props;
  const isLargerThanLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [sideToShow, setSideToShow] = useState<SideToShowOption>(defaultSide);
  const onOpen = useCallback((side: Side = 'LEFT') => {
    setSideToShow(prev => {
      if (side === 'LEFT') {
        if (prev === SideToShowOption.OnlyRight && isLargerThanLg) {
          return SideToShowOption.LeftAndRight;
        }
        return SideToShowOption.OnlyLeft;
      }
      if (side === 'RIGHT') {
        if (prev === SideToShowOption.OnlyLeft && isLargerThanLg) {
          return SideToShowOption.LeftAndRight;
        }
        return SideToShowOption.OnlyRight;
      }
      return prev;
    });
  }, [isLargerThanLg]);
  const onClose = useCallback((side: Side = 'LEFT') => {
    setSideToShow(prev => {
      if (side === 'LEFT') {
        if (prev === SideToShowOption.LeftAndRight) {
          return SideToShowOption.OnlyRight;
        }
        return SideToShowOption.None;
      }
      if (side === 'RIGHT') {
        if (prev === SideToShowOption.LeftAndRight) {
          return SideToShowOption.OnlyLeft;
        }
        return SideToShowOption.None;
      }
      return prev;
    });
  }, []);
  const onToggle = useCallback((side: Side = 'LEFT') => {
    setSideToShow(prev => {
      if (side === 'LEFT') {
        if (prev === SideToShowOption.LeftAndRight) {
          return SideToShowOption.OnlyRight;
        }
        if (prev === SideToShowOption.None) {
          return SideToShowOption.OnlyLeft;
        }
        if (prev === SideToShowOption.OnlyLeft) {
          return SideToShowOption.None;
        }
        if (prev === SideToShowOption.OnlyRight) {
          return isLargerThanLg ? SideToShowOption.LeftAndRight : SideToShowOption.OnlyRight;
        }
      }
      if (side === 'RIGHT') {
        if (prev === SideToShowOption.LeftAndRight) {
          return SideToShowOption.OnlyLeft;
        }
        if (prev === SideToShowOption.OnlyRight) {
          return SideToShowOption.None;
        }
        if (prev === SideToShowOption.OnlyLeft) {
          return isLargerThanLg ? SideToShowOption.LeftAndRight : SideToShowOption.OnlyRight;
        }
        if (prev === SideToShowOption.None) {
          return SideToShowOption.OnlyRight;
        }
      }
      return prev;
    });
  }, [isLargerThanLg]);
  return <AppShellContext.Provider value={{
    onOpen,
    onClose,
    onToggle,
    isLargerThanLg,
    sideToShow
  }} data-sentry-element="unknown" data-sentry-component="AppShellProvider" data-sentry-source-file="context.tsx">
      {children}
    </AppShellContext.Provider>;
};