'use client';

import { Box, MobileStepper, Stack } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'next/navigation';
import { type FC, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
type Props = {
  list: ReactNode[];
  focusNames?: string[];
  needProgress?: boolean;
};
export const MobileSwipeableViews: FC<Props> = props => {
  const {
    list,
    needProgress = false,
    focusNames = []
  } = props;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = useMemo(() => list.filter(step => step !== null && step !== undefined).length, [list]);
  const searchParams = useSearchParams();
  const crtFocusName = useMemo(() => searchParams.get('focus'), [searchParams]);
  const handleStepChange = useCallback((step: number) => {
    setActiveStep(step);
  }, [setActiveStep]);
  useEffect(() => {
    if (focusNames.length === 0 || !crtFocusName) {
      return;
    }
    const stepIdx = focusNames.indexOf(crtFocusName);
    if (stepIdx < 0) {
      return;
    }
    setActiveStep(stepIdx);
  }, [crtFocusName, focusNames]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  if (!isMobile) {
    return null;
  }
  return <Stack direction="column" spacing={2} data-sentry-element="Stack" data-sentry-component="MobileSwipeableViews" data-sentry-source-file="index.tsx">
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents data-sentry-element="SwipeableViews" data-sentry-source-file="index.tsx">
        {list.filter(step => step !== null && step !== undefined).map((step: ReactNode, index: number): ReactNode => <div key={`swiper-step-${index}`} style={{
        height: '100%'
      }}>
                {Math.abs(activeStep - index) <= 2 ? <Box component="div" sx={{
          height: '100%'
        }}>
                    {step}
                  </Box> : null}
              </div>)}
      </SwipeableViews>
      {needProgress && <MobileStepper steps={maxSteps} position="static" variant="progress" activeStep={activeStep} nextButton={<Box component="div" />} color="primary.main" backButton={<Box component="div" />} sx={{
      backgroundColor: 'transparent'
    }} />}
    </Stack>;
};