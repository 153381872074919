'use client';

import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fr from 'date-fns/locale/fr';
import type { FC, ReactNode } from 'react';
type Props = {
  children: ReactNode;
};
export const LocalizationProvider: FC<Props> = props => {
  const {
    children
  } = props;
  return <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr} data-sentry-element="MuiLocalizationProvider" data-sentry-component="LocalizationProvider" data-sentry-source-file="localization.provider.tsx">
      {children}
    </MuiLocalizationProvider>;
};