'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#e7f7f2',
      gradient: {
        main: 'linear-gradient(to right, #13BF56 0%, #10B17E 51.56%, #19A2A7 100%)',
        dark: 'linear-gradient(to right, #0fa74a 0%, #0d9469 51.56%, #148488 100%)',
        light:
          'linear-gradient(to right, #0fa74a 0%, #0d9469 51.56%, #148488 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-s-c'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
