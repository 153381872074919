'use client';

import { createTheme } from '@mui/material/styles';
import { applyPrimaryColor } from './apply-primary-color';
import theme from './base';

// Create a theme instance.
const base = createTheme(theme, {
  palette: {
    background: {
      primary: '#fff8e8',
      gradient: {
        main: 'linear-gradient(to right, #FD9622 0%, #FCB91B 51.56%, #D1BF1F 100%)',
        dark: 'linear-gradient(to right, #e6881f 0%, #e6a918 51.56%, #c1b11b 100%)',
        light:
          'linear-gradient(to right, #e6881f 0%, #e6a918 51.56%, #c1b11b 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette['disc-i'],
  },
});

export const light = applyPrimaryColor(base);

export const dark = light;
