'use client';

import { createTheme } from '@mui/material/styles';
import theme from './base';

export const light = createTheme(theme, {
  palette: {
    ...theme.palette,

    mode: 'light',

    // contrastThreshold: 2,

    background: {
      default: '#f4f9f9',
      primary: '#e5fafa',
      gray: '#2D5B5B',
      paper: '#fff',
      gradient: {
        main: 'linear-gradient(to right, #00ced1 0%, #009ca0 100%)',
        dark: 'linear-gradient(to right, #73e4e5 0%, #00ced1 100%)',
        light: 'linear-gradient(to right, #73e4e5 0%, #00ced1 100%)',
        contrastText: '#fff',
      },
    },

    primary: theme.palette.augmentColor({
      color: {
        main: '#009ca0',
        contrastText: '#FFF',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#00ced1',
      },
      name: 'secondary',
    }),

    divider: '#7b9899',

    accepted: theme.palette.augmentColor({
      color: {
        main: '#52c41a',
        light: '#F6FFED',
        dark: '#52c41a',
        contrastText: '#FFF',
      },
      name: 'accepted',
    }),
    pending: theme.palette.augmentColor({
      color: {
        main: '#faad14',
        light: '#FFFBE6',
        dark: '#faad14',
        contrastText: '#FFF',
      },
      name: 'pending',
    }),
    refused: theme.palette.augmentColor({
      color: {
        main: '#ff4d4f',
        light: '#FFF2F0',
        dark: '#ff4d4f',
        contrastText: '#FFF',
      },
      name: 'refused',
    }),
    canceled: theme.palette.augmentColor({
      color: {
        main: '#F59AA4',
        light: '#FFF0F6',
        dark: '#c41d7f',
        contrastText: '#FFF',
      },
      name: 'canceled',
    }),
    expired: theme.palette.augmentColor({
      color: {
        main: '#c41d7f',
        light: '#c41d7f',
        dark: '#c41d7f',
        contrastText: '#FFF',
      },
      name: 'expired',
    }),
    deleted: theme.palette.augmentColor({
      color: {
        main: '#ff4a4a',
        light: '#ff4a4a',
        dark: '#ff4a4a',
        contrastText: '#FFF',
      },
      name: 'deleted',
    }),

    common: {
      black: '#000',
      white: '#fff',
    },

    poleEmploi: theme.palette.augmentColor({
      color: {
        main: '#1b2e57',
      },
      name: 'poleEmploi',
    }),
    linkedIn: theme.palette.augmentColor({
      color: {
        main: '#0077b5',
      },
      name: 'linkedIn',
    }),

    admin: theme.palette.augmentColor({
      color: {
        main: '#52c41a',
        light: '#F6FFED',
        dark: '#52c41a',
        contrastText: '#FFF',
      },
      name: 'linkedIn',
    }),
    tooler: theme.palette.augmentColor({
      color: {
        main: '#8BB4AD',
        light: '#E6FFFB',
        dark: '#8BB4AD',
        contrastText: '#FFF',
      },
      name: 'linkedIn',
    }),
  },
});

export const dark = light;
