'use client';

import { Box, Drawer, Stack, type Theme } from '@mui/material';
import { type DrawerProps, drawerClasses } from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type FC, type ReactNode, useMemo } from 'react';
import { useAppShell } from './context';
import { SideToShowOption } from './types';
type Props = {
  containerWidth?: string | number;
  drawerWidth?: string | number;
  drawerMobileWidth?: string | number;
  children: ReactNode;
  DrawerRender: ReactNode;
  RightDrawerRender?: ReactNode;
  mdVariant?: DrawerProps['variant'];
};
export const AppShell: FC<Props> = props => {
  const {
    children,
    containerWidth = 'auto',
    drawerWidth = '30%',
    drawerMobileWidth = '80%',
    DrawerRender,
    RightDrawerRender,
    mdVariant = 'permanent'
  } = props;
  const {
    sideToShow,
    onClose,
    isLargerThanLg
  } = useAppShell();
  const isVisible = useMemo(() => sideToShow === SideToShowOption.OnlyLeft || sideToShow === SideToShowOption.LeftAndRight, [sideToShow]);
  const mdWidth = useMemo(() => {
    if (mdVariant !== 'permanent') {
      return isVisible ? drawerWidth : 0;
    }
    return drawerWidth;
  }, [mdVariant, isVisible, drawerWidth]);
  const mdFlexShrink = useMemo(() => {
    if (mdVariant !== 'permanent') {
      return isVisible ? 0 : 1;
    }
    return 0;
  }, [mdVariant, isVisible]);
  const isLargerThanMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const hideOnMobile = useMemo(() => {
    return drawerMobileWidth === 0 && !isLargerThanMd;
  }, [drawerMobileWidth, isLargerThanMd]);
  if (hideOnMobile) {
    return <Box component="div" width="100%" minHeight="100%" overflow={{
      none: 'auto',
      md: 'hidden auto'
    }}>
        {children}
      </Box>;
  }
  return <Stack direction="row" minHeight="100%" width={containerWidth} data-sentry-element="Stack" data-sentry-component="AppShell" data-sentry-source-file="app-shell.tsx">
      <Box component="nav" sx={{
      width: {
        md: mdWidth
      },
      flexShrink: {
        md: mdFlexShrink
      },
      transition: 'all 300ms'
    }} data-sentry-element="Box" data-sentry-source-file="app-shell.tsx">
        <Drawer variant={isLargerThanMd ? mdVariant : 'temporary'} anchor={isLargerThanMd ? undefined : 'left'} open={isVisible} sx={{
        [`& .${drawerClasses.paper}`]: {
          boxSizing: 'border-box',
          width: isLargerThanMd ? drawerWidth : drawerMobileWidth,
          border: 'none',
          zIndex: 999
        }
      }} onClose={() => onClose()} PaperProps={{
        variant: 'elevation',
        elevation: 3
      }} data-sentry-element="Drawer" data-sentry-source-file="app-shell.tsx">
          {DrawerRender}
        </Drawer>
      </Box>

      <Box component="div" minHeight="100%" overflow={{
      none: 'auto',
      md: 'hidden auto'
    }} flex={1} data-sentry-element="Box" data-sentry-source-file="app-shell.tsx">
        {children}
      </Box>

      {RightDrawerRender}
    </Stack>;
};