'use client';

import { styled } from '@mui/material/styles';
import { DateCalendar as MuiDateCalendar, type DateCalendarProps as MuiDateCalendarProps } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, type PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import endOfDay from 'date-fns/endOfDay';
import isEqual from 'date-fns/isEqual';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import { useEffect, useMemo } from 'react';
export type DateCalendarProps<TDate extends Date> = MuiDateCalendarProps<TDate>;
export const DateCalendar = MuiDateCalendar;
interface CustomPickerDayProps extends PickersDayProps<Date> {
  dayIsBetween: boolean;
  isStartDay: boolean;
  isEndDay: boolean;
  isStartSelected: boolean;
}
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== 'dayIsBetween' && prop !== 'isStartDay' && prop !== 'isEndDay' && prop !== 'isStartSelected'
})<CustomPickerDayProps>(({
  theme,
  dayIsBetween,
  isStartDay,
  isEndDay,
  isStartSelected
}) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderTopColor: theme.palette.primary.light,
    borderBottomColor: theme.palette.primary.light,
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    // backgroundColor: theme.palette.primary.light,
    // color: theme.palette.primary.light,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white
    }
  }),
  ...(isStartDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.light,
    borderLeftWidth: '1px',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderTopColor: theme.palette.primary.light,
    borderBottomColor: theme.palette.primary.light,
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    backgroundColor: theme.palette.primary.light
  }),
  ...(isEndDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.light,
    borderRightWidth: '1px',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderTopColor: theme.palette.primary.light,
    borderBottomColor: theme.palette.primary.light,
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    backgroundColor: theme.palette.primary.light
  }),
  ...(isStartDay && isStartSelected && {
    backgroundColor: theme.palette.primary.main
  }),
  ...(isEndDay && !isStartSelected && {
    backgroundColor: theme.palette.primary.main
  })
})) as React.ComponentType<CustomPickerDayProps>;
export function RangeDay(props: PickersDayProps<Date> & {
  isStartSelected?: boolean;
  startDay?: Date | null;
  endDay?: Date | null;
}) {
  const {
    day,
    startDay,
    endDay,
    isStartSelected = true,
    ...other
  } = props;
  const dayIsBetween = useMemo(() => {
    if (!startDay || !endDay) {
      return false;
    }
    const currentEnd = day;
    return isWithinInterval(currentEnd, {
      start: startOfDay(startDay),
      end: endOfDay(endDay)
    });
  }, [startDay, endDay]);
  const isStartDay = useMemo(() => {
    if (!startDay) {
      return false;
    }
    const currentEnd = startOfDay(day);
    return isEqual(currentEnd, startOfDay(startDay));
  }, [startDay]);
  const isEndDay = useMemo(() => {
    if (!endDay) {
      return false;
    }
    const currentEnd = endOfDay(day);
    return isEqual(currentEnd, endOfDay(endDay));
  }, [endDay]);
  return <CustomPickersDay {...other} day={day} sx={dayIsBetween ? {
    px: 2.5,
    mx: 0
  } : {}} dayIsBetween={dayIsBetween} isStartDay={isStartDay} isEndDay={isEndDay} isStartSelected={isStartSelected} data-sentry-element="CustomPickersDay" data-sentry-component="RangeDay" data-sentry-source-file="index.tsx" />;
}