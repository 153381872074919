'use client';

import { Box, Stack, Typography } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import { type ComponentProps, type FC, useMemo } from 'react';
import styles from './po-loader.module.css';
type Props = {
  scale?: number;
  texts?: string[];
  sx?: ComponentProps<typeof Box>['sx'];
  color?: keyof Theme['palette'];
};
export const PoLoader: FC<Props> = props => {
  const {
    scale = 1,
    texts = [],
    sx = {},
    color = 'primary'
  } = props;
  const theme = useTheme();
  const svgColor = useMemo(() => color in theme.palette && typeof theme.palette[color] === 'object' && 'main' in theme.palette[color] && typeof theme.palette[color].main === 'string' ? theme.palette[color].main : theme.palette.primary.main, [theme, color]);
  return <Stack className={styles['triangle-loader-container']} data-sentry-element="Stack" data-sentry-component="PoLoader" data-sentry-source-file="index.tsx">
      <Box component="div" className={styles.loader} sx={{
      transform: `scale(${scale})`,
      ...sx
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <svg viewBox="0 0 86 80" color={svgColor ?? theme.palette.primary.main} data-sentry-element="svg" data-sentry-source-file="index.tsx">
          <polygon points="43 8 79 72 7 72" data-sentry-element="polygon" data-sentry-source-file="index.tsx" />
        </svg>
      </Box>
      {texts.length !== 0 && <Box component="div" className={styles['loading-phrases-container']}>
          <Box component="div" className={styles['loading-phrases']}>
            {texts.map((text, i) => <Typography key={text}>{text}</Typography>)}
          </Box>
        </Box>}
    </Stack>;
};