'use client';

import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'next/image';
import type { FC, ReactNode } from 'react';
import { SidebarToggle } from '../sidebar-toggle/wrapper';
type Props = {
  pageImg?: any;
  pageComponent?: ReactNode;
  pageTitleRender: ReactNode;
  actionBtn?: ReactNode;
};
export const PageHeader: FC<Props> = (props: Props) => {
  const {
    pageImg = null,
    pageComponent = undefined,
    pageTitleRender,
    actionBtn = undefined
  } = props;
  return <Stack position="relative" flexDirection="row" justifyContent="space-between" alignItems="center"
  // flexWrap="wrap"
  padding={{
    none: '0 1rem',
    md: 2
  }} marginBottom={{
    none: '1.5rem',
    md: 0
  }} bgcolor={{
    none: 'background.primary',
    md: 'transparent'
  }} data-sentry-element="Stack" data-sentry-component="PageHeader" data-sentry-source-file="index.tsx">
      <Stack flexDirection="row" alignItems="center" gap={2} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Box component="div" sx={{
        display: {
          none: 'none',
          md: 'block'
        }
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <SidebarToggle data-sentry-element="SidebarToggle" data-sentry-source-file="index.tsx" />
        </Box>
        {!!pageImg ? <Box component="div" position="relative" width="3rem" height="3rem" sx={{
        display: {
          none: 'none',
          md: 'block'
        }
      }}>
            <Image src={pageImg} alt="" style={{
          objectFit: 'contain',
          objectPosition: 'center'
        }} fill />
          </Box> : <>{pageComponent}</>}
        <Stack component="h1" flexDirection="row" alignItems="center" gap={1} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          {pageTitleRender}
        </Stack>
      </Stack>
      {actionBtn && <Box component="div" sx={{
      display: {
        none: 'block',
        md: 'none'
      }
    }}>
          {actionBtn}
        </Box>}
    </Stack>;
};