'use client';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Container, Paper, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
type Props = {
  description: string;
  elevation?: number;
};
export const NoResultCard: FC<Props> = (props: Props) => {
  const {
    description,
    elevation = 3
  } = props;
  return <Stack direction="row" justifyContent="center" data-sentry-element="Stack" data-sentry-component="NoResultCard" data-sentry-source-file="index.tsx">
      <Container maxWidth="xs" data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <Paper elevation={elevation} data-sentry-element="Paper" data-sentry-source-file="index.tsx">
          <Stack direction="column" gap={2} alignItems="center" padding={3} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
            <HighlightOffRoundedIcon fontSize="large" color="primary" data-sentry-element="HighlightOffRoundedIcon" data-sentry-source-file="index.tsx" />
            <Typography textAlign="center" data-sentry-element="Typography" data-sentry-source-file="index.tsx">{description}</Typography>
          </Stack>
        </Paper>
      </Container>
    </Stack>;
};