'use client';

import { type FC, type PropsWithChildren, useMemo } from 'react';
import { AppPalettes, DiscCDPalettes, DiscCPalettes, DiscDIPalettes, DiscDPalettes, DiscIPalettes, DiscISPalettes, DiscSCPalettes, DiscSPalettes, type Theme, ToolsPalettes } from '../themes';
import { ThemeProvider } from './theme.provider';
type Props = {
  theme: 'ADVISOR' | 'ANALYST' | 'ANIMATOR' | 'COACH' | 'PEACEMAKER' | 'PILOT' | 'PLANNER' | 'PROTECTOR' | 'APP' | 'TOOLS';
};
export const ThemeServerProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    theme,
    children
  } = props;
  const palettes = useMemo((): Theme => {
    switch (theme) {
      case 'PILOT':
        return DiscDPalettes.light;
      case 'COACH':
        return DiscDIPalettes.light;
      case 'ANIMATOR':
        return DiscIPalettes.light;
      case 'PEACEMAKER':
        return DiscISPalettes.light;
      case 'ADVISOR':
        return DiscSPalettes.light;
      case 'PROTECTOR':
        return DiscSCPalettes.light;
      case 'ANALYST':
        return DiscCPalettes.light;
      case 'PLANNER':
        return DiscCDPalettes.light;
      case 'TOOLS':
        return ToolsPalettes.light;
      default:
        return AppPalettes.light;
    }
  }, [theme]);
  const key = useMemo(() => `theme-server-provider-${theme}`.toLowerCase(), [theme]);
  return <ThemeProvider theme={palettes} options={{
    key
  }} data-sentry-element="ThemeProvider" data-sentry-component="ThemeServerProvider" data-sentry-source-file="theme.server-provider.tsx">
      {children}
    </ThemeProvider>;
};