'use client';

import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
export type TextFieldInputProps<T extends FieldValues = FieldValues> = Omit<MuiTextFieldProps, 'name'> & {
  //   validation?: ControllerProps['rules']
  name: Path<T>;
  //   parseError?: (error: FieldError) => string
  control?: Control<T>;
  /**
   * You override the MUI's TextField component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of TextField.
   */
  component?: typeof MuiTextField;
  disableError?: boolean;
};
export function TextFieldInput<TFieldValues extends FieldValues = FieldValues>(props: TextFieldInputProps<TFieldValues>) {
  const {
    control,
    name,
    component: TextFieldComponent = MuiTextField,
    helperText = undefined,
    hidden = false,
    disableError = false,
    ...baseProps
  } = props;
  return <Controller control={control} name={name} render={({
    field: {
      ref,
      ...field
    },
    fieldState: {
      invalid,
      error
    }
  }) => <TextFieldComponent inputRef={ref} error={invalid} helperText={!disableError && invalid && error?.message ? error?.message : helperText} {...baseProps} {...field} />} data-sentry-element="Controller" data-sentry-component="TextFieldInput" data-sentry-source-file="index.tsx" />;
}